import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import "./App.scss";
import { NavBar, Image } from "antd-mobile";
import { ConfigProvider } from "antd-mobile";
import frFR from "antd-mobile/es/locales/fr-FR";
import { HistChelems } from "./components/hist";
import { Chelem } from "./components/chelem";
import { Course } from "./components/course";
import ProvideNavbar, { useNavbar } from "./use-navbar";

const MyNavBar = () => {
  const navbar = useNavbar();
  return (
    <NavBar
      back={navbar?.back}
      backIcon={navbar?.backArrow}
      onBack={() => {
        console.log("onBack");
        navbar && navbar.onBack && navbar.onBack();
      }}
      right={navbar?.right}
    >
      {navbar?.title || <Image src="/assets/Mario_Kart_logo.png" />}
    </NavBar>
  );
};

function App() {
  // const [value, setValue] = useState("hist");
  // const handleNav = (event: any, newValue: string) => {
  //   setValue(newValue);
  // };

  function Layout() {
    return (
      <div className="app">
        <div className="banner">
          <MyNavBar />
        </div>
        <div className="body">
          <Outlet />
        </div>
      </div>
    );
  }
  return (
    <ConfigProvider locale={frFR}>
      <ProvideNavbar>
        <BrowserRouter>
          {/* <Route path="/products/:id" component={Product} /> */}
          <Routes>
            <Route element={<Layout />}>
              <Route path="/" element={<HistChelems />} />
              <Route path="new" element={<Chelem new={true} />} />
              <Route path="/:chelemId" element={<Chelem />} />
              <Route path="/:chelemId/:courseName" element={<Course />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ProvideNavbar>
    </ConfigProvider>
  );
}

export default App;
