import { useEffect, useState, FC } from "react";
import {
  ChelemModel,
  ChelemDTO,
  dtoToModel,
  initialChelem,
} from "./mkart.types";
import { useParams, useNavigate } from "react-router-dom";
import {
  Image,
  ProgressCircle,
  List,
  Button,
  AutoCenter,
  NoticeBar,
  Mask,
  Space,
} from "antd-mobile";
import { ClockCircleFill } from "antd-mobile-icons";
import axios from "axios";
import { useNavbar } from "../use-navbar";

interface ChelemProps {
  new?: boolean;
}

const listPos: Array<"j1" | "j2"> = ["j1", "j2"];
// attribute points from position

const formatDate = (d: Date | undefined) => {
  if (!d) {
    return "";
  }
  return d.toLocaleTimeString("fr");
};

export const Chelem: FC<ChelemProps> = ({ new: isNew }: ChelemProps) => {
  let navigate = useNavigate();
  let { chelemId } = useParams<"chelemId">();
  const navbar = useNavbar();

  const [chelem, setChelem] = useState<ChelemModel>(initialChelem);
  const [open, setOpen] = useState(false);
  const [isBackdropOpen, setBackdropOpen] = useState(false);

  useEffect(() => {
    const returnToHome = () => {
      navigate("/");
    };
    if (navbar) {
      navbar.setBackArrow(true);
      navbar.setOnBack(returnToHome);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chelemId, navigate]);

  useEffect(() => {
    async function fetchChelemData() {
      let chelemResult: ChelemModel = initialChelem;
      if (chelemId && chelemId !== "new") {
        console.log("load chelem " + chelemId);
        const response = await axios.get<ChelemDTO>("/api/chelems/" + chelemId);
        chelemResult = dtoToModel(response.data);
      }
      setChelem(chelemResult);
    }

    fetchChelemData();
  }, [chelemId, isNew]);
  // get stats for each courses

  const handleClose = () => {
    setOpen(false);
  };

  const isBong = [0, 5, 10, 15].includes(chelem.nb);
  useEffect(() => {
    // if (isBong) {
    setBackdropOpen(isBong);
    // }
  }, [isBong, chelem.nb]);

  return (
    <>
      <List mode="card">
        <List.Item
          className={"checker-background"}
          prefix={
            <ProgressCircle
              percent={(chelem.nb / 16) * 100}
              style={{
                "--size": "80px",
                "--track-width": "9px",
              }}
            >
              {chelem.nb} / 16
            </ProgressCircle>
          }
          extra={
            <>
              {chelem.nb
                ? Math.round(
                    ((chelem.ptsJ1 + chelem.ptsJ2) / (chelem.nb * 2)) * 100
                  ) / 100
                : 0}
            </>
          }
          description={
            <>
              Max: {chelem.ptsJ1 + (16 - chelem.nb) * 9} -{" "}
              {chelem.ptsJ2 + (16 - chelem.nb) * 9}{" "}
              {Math.round(
                ((chelem.ptsJ1 + chelem.ptsJ2 + (16 - chelem.nb) * 18) /
                  (16 * 2)) *
                  100
              ) / 100}
            </>
          }
        >
          <AutoCenter>
            {chelem.ptsJ1} - {chelem.ptsJ2}
          </AutoCenter>
        </List.Item>
      </List>
      <List mode="card">
        {chelem.courses.map((course) => (
          <List.Item
            className={
              course.result.j1 && course.result.j2
                ? "tires-background"
                : "checker-background"
            }
            key={course.name}
            onClick={() => navigate(`/${chelem._id || "new"}/${course.name}`)}
            prefix={
              <Image
                src={"/img/" + course.img}
                style={{ borderRadius: 50 }}
                fit="cover"
                width={50}
                height={50}
              />
            }
            title={
              <Space justify="between">
                {course.result.date ? (
                  <Space
                    style={{
                      borderRadius: 15,
                      padding: 4,
                      paddingLeft: 10,
                    }}
                    className="checker-blue-background-inner"
                    direction="horizontal"
                    align="center"
                    justify="center"
                  >
                    {formatDate(course.result.date)}
                    {[...Array(course.result.stars)].map((i) => (
                      <Image
                        src="/assets/th_star.png"
                        width={15}
                        // className="bounce-2 animate-1"
                      />
                      // <StarFill color="#F1CF47" />
                    ))}
                    {course.result.bigGuyIsLast && (
                      <Image
                        src="/assets/th_ghost.png"
                        width={15}
                        // className="bounce-2 animate-1"
                      />
                    )}
                    {course.result.chronoInMs > 0 && (
                      <ClockCircleFill color="#2bdb17" />
                    )}
                  </Space>
                ) : (
                  <></>
                )}
              </Space>
            }
            // description={formatDate(course.result.date)}
            extra={
              course.result.j1 === 1 && course.result.j2 === 1 ? (
                <>
                  <button className="subscribe-button">
                    {course.result.j1}
                  </button>
                  <button className="subscribe-button">
                    {course.result.j2}
                  </button>
                </>
              ) : (
                listPos.map((pos) => (
                  <Button
                    key={pos}
                    size="small"
                    style={{
                      borderRadius: "50%",
                      width: 35,
                      height: 35,
                      minWidth: 30,
                    }}
                    fill={course.result[pos] > 0 ? "solid" : "outline"}
                    color={
                      [1, 2].includes(course.result[pos])
                        ? "success"
                        : [3, 4, 5, 6].includes(course.result[pos])
                        ? "warning"
                        : [7, 8].includes(course.result[pos])
                        ? "danger"
                        : "primary"
                    }
                  >
                    {course.result[pos]}
                  </Button>
                ))
              )
            }
          >
            {course.name}
          </List.Item>
        ))}
      </List>
      {open && (
        <NoticeBar
          onClose={handleClose}
          closeable
          content="Enregistrement OK"
        />
      )}

      <Mask
        visible={isBackdropOpen}
        onMaskClick={() => {
          setBackdropOpen(false);
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "240px",
            height: "150px",
            marginTop: "-75px",
            marginLeft: "-120px",
            background: "white",
            borderRadius: "16px",
          }}
        >
          <Space direction="vertical" align="center">
            <Image
              src="/assets/th_ghost.png"
              width={150}
              className="bounce-1 animate-1"
            />
            <h2>Hit from the Bong !!</h2>
            <Image
              src="/assets/th_star.png"
              width={150}
              className="bounce-2 animate-1"
            />
          </Space>
        </div>
      </Mask>
    </>
  );
};
