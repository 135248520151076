/* eslint-disable @typescript-eslint/no-empty-function */
import { NavBarProps } from "antd-mobile/es/components/nav-bar";
import React, { useState, useContext, createContext, ReactNode } from "react";

interface navbarContextInterface extends NavBarProps {
  title: string;
  setTitle: (title: string) => void;
  setBackArrow: (b: boolean) => void;
  setBack: (b: string | null | undefined) => void;
  setOnBack: (onBack: () => void) => void;
  setRight: (r?: ReactNode | undefined) => void;
  onBack: () => void;
}

const navbarContext = createContext<navbarContextInterface | null>(null);
// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().

const ProvideNavbar = ({ children }: { children: ReactNode }) => {
  const navbar = useProvideNavbar();
  return (
    <navbarContext.Provider value={navbar}>{children}</navbarContext.Provider>
  );
};
export default ProvideNavbar;

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useNavbar = () => {
  return useContext(navbarContext);
};
// Provider hook that creates auth object and handles state
function useProvideNavbar(): navbarContextInterface {
  const [title, setTitle] = useState("");
  const [back, setBack] = useState<string | null | undefined>();
  const [backArrow, setBackArrow] = useState(false);
  const [right, setRight] = useState<ReactNode | undefined>();
  const [onBack, setOnBack] = useState<() => void>(() => {});

  const logSetOnBack = (onBack: () => void) => {
    setOnBack(() => () => onBack());
  };
  const logSetRight = (right: ReactNode | undefined) => {
    setRight(right);
  };

  return {
    title,
    setTitle,
    backArrow,
    setBackArrow,
    back,
    setBack,
    onBack,
    setOnBack: logSetOnBack,
    right,
    setRight: logSetRight,
    // back?: string | null | undefined;
    // backArrow?: boolean | ReactNode;
    // left?: ReactNode;
    // right?: ReactNode;
    // onBack?: (() => void) | undefined;
  };
}
