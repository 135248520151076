import { useState, FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ChelemDTO, ChelemModel, dtoToModel } from "./mkart.types";
import axios from "axios";
import { Switch, List, FloatingBubble } from "antd-mobile";
import {
  FlagOutline,
  UserOutline,
  StarOutline,
  AddCircleOutline,
} from "antd-mobile-icons";

interface HistProps {}

const formatDate = (d: Date | undefined) => {
  var options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  if (!d) {
    return "";
  }
  return d.toLocaleDateString("fr", options);
};

export const HistChelems: FC<HistProps> = () => {
  const navigate = useNavigate();
  const [chelems, setchelems] = useState<ChelemModel[]>([]);
  useEffect(() => {
    console.log("reload from api");
    axios.get<ChelemDTO[]>("/api/chelems").then((r) => {
      const chelemsResult = r.data
        .map((c) => {
          if (c.date) {
            c.date = new Date(c.date);
          } else {
            c.date = new Date();
          }

          return dtoToModel(c);
        })
        // @ts-ignore
        .sort((c1, c2) => c2.date.getTime() - c1.date.getTime());
      setchelems(chelemsResult);
    });
  }, []);

  const [isFullListEnabled, setisFullListEnabled] = useState(false);
  const Chip = ({
    icon,
    label,
    sx,
    size,
    color,
    variant,
  }: {
    icon: any;
    label: string;
    sx?: any;
    size?: string;
    color?: string;
    variant?: string;
  }) => {
    return (
      <>
        {icon}
        {label}
      </>
    );
  };
  return (
    <>
      <List header="" className="battle-background">
        <List.Item
          arrowIcon=""
          extra={
            <Switch
              checked={isFullListEnabled}
              onChange={setisFullListEnabled}
            />
          }
          onClick={() => setisFullListEnabled(!isFullListEnabled)}
        >
          Voir tout
        </List.Item>
        {chelems
          .filter((c) => isFullListEnabled || c.nb === 16)
          .map((chelem) => (
            <List.Item
              onClick={() => navigate(`/${chelem._id}`)}
              key={chelem._id}
              prefix={
                <Chip
                  icon={<FlagOutline />}
                  sx={{ fontSize: ".8rem", background: "#417BCB" }}
                  size="medium"
                  label={`${chelem.nb}/16`}
                  color={chelem.nb === 16 ? "success" : "default"}
                />
              }
              description={formatDate(chelem.date)}
              extra={
                <Chip
                  icon={<StarOutline />}
                  sx={{
                    fontSize: ".8rem",
                    background: chelem.nb === 16 ? "#F1CF47" : "#F1CF47",
                  }}
                  label={(chelem.nb > 0
                    ? Math.round(
                        (((chelem.ptsJ1 + chelem.ptsJ2) / 2) * 1000) / chelem.nb
                      ) / 1000
                    : 0
                  ).toString()}
                />
              }
            >
              <Chip
                icon={<UserOutline color="#5AAE3C" />}
                sx={{
                  fontSize: ".8rem",
                  fontWeight: "bold",
                  color: "#5AAE3C",
                  border: "0px",
                }}
                variant="outlined"
                label={`${chelem.ptsJ1} - ${chelem.ptsJ2}`}
              />
            </List.Item>
          ))}
      </List>
      <FloatingBubble
        style={{
          "--initial-position-bottom": "24px",
          "--initial-position-right": "24px",
          "--edge-distance": "24px",
        }}
        onClick={() => navigate("/new")}
      >
        <AddCircleOutline fontSize={32} />
      </FloatingBubble>
    </>
  );
};
